import React from 'react';
// import background from 'assets/images/banner-img.png';
import banngerImg from 'assets/images/banner.svg';
// import isBrowser from 'components/lib/isbrowser'

// const styles = () => ({
//     backgroundImage: `url(${background})`
// });

const Banner = ({ title, description, className, topTitle, rightContent }) => (
  <>
    <section id="banner" className={className || null}>
      <div className="inner">
        <div className="banner-content">
          <header className="major">
            {topTitle && <div className="topTitle"><div className="line"></div>{topTitle}</div>}
            <h1>{title}</h1>
          </header>

          {description ? (
            <div className="content">
              <p>{description}</p>
            </div>
          ) : null}
        </div>
      </div>
      {rightContent || (
        <figure className="banner-cover-wrapper">
          <img src={banngerImg} alt="banner" />
        </figure>
      )}
    </section>
  </>
)

export default Banner
