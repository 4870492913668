import React from 'react';
import Helmet from 'react-helmet';

import Banner from 'components/banner';
import Layout from 'components/layout';
import './page.scss';

export default ({ content }) =>
  <Layout>
    <Helmet title={content.frontmatter.title} />

    {content.frontmatter.bannerVisibility !== "hide" && <Banner {...content.frontmatter} />}

    <div id="main">
      <section className="section section--gradient">
        <div className="inner content">
          <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: content.html }} />
        </div>
      </section>
    </div>
  </Layout>