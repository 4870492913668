import React from 'react';
import { graphql } from 'gatsby';

import pageContent from 'components/lib/page-content';
import Page from 'components/page';

const PageTemplate = ({ data }) => {
  const content = pageContent(data);

  return <Page content={content} />
}

export default PageTemplate;

export const pageQuery = graphql`
    query PageQuery ($path: String!) {
        ...MarkdownPages
    }
`